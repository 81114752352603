@import url('https://fonts.googleapis.com/css2?family=Alata&Open+Sans:wght@300;400&display=swap');

html,
body {
  position: relative;
  overflow: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; 
}

.App {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.App-logo {
  min-height: 125px;
  width: 100%;
  background: url(./assets/images/logo.svg) no-repeat center top !important;
  background-size: 500px 125px !important;
}

.App-header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-main {
  min-height: 50vh;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  min-height: 20vh;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  color: white;
}

.Info-model {
  font-family: 'Open Sans', sans-serif;
}

.Info-model h2 {
  font-family: 'Alata', sans-serif;
  text-transform: uppercase;
  font-weight: 100;
}

.Info-model a {
  color: #fff;
}

.Info-model a:hover {
  text-decoration: none;
}