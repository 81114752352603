
.current-interval-1 .box-right,
.current-interval-2 .box-top,
.current-interval-3 .box-left,
.current-interval-4 .box-bottom
{
  font-size: .6em;
  animation:  box-text-in .5s ease-in;
  color:rgba(255, 255, 255, 1);
}

.box-text {
  font-size: .5em;
  position: absolute;
  color:rgba(255, 255, 255, 0.5);
  animation:  box-text-out .5s ease-out;
}

@keyframes box-text-in {
  from {
    font-size: .5em;
    color:rgba(255, 255, 255, .5);
  }
  to {
    font-size: .6em;
    color:rgba(255, 255, 255, 1);
  }
}

@keyframes box-text-out {
  from {
    font-size: .6em;
    color:rgba(255, 255, 255, 1);
  }
  to {
    font-size: .5em;
    color:rgba(255, 255, 255, .5);

  }
}

.box-right {
  left: -55%;
  bottom: 50%;
  width: 100%;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
}

.box-top {
  top: -8%;
  width: 100%;
}

.box-left {
  right: -55%;
  bottom: 50%;
  width: 100%;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.box-bottom {
  bottom: -8%;
  width: 100%;
}

.line {
  background: rgb(255, 255, 255);
  position: absolute;
}

.line1 {
  height: 0;
  width: 1%;
  bottom: 1%;
}

.line2 {
  height: 1%;
  width: 0;
  left: 1%;
  top: 0;
}


.line3 {
  height: 0;
  width: 1%;
  top: 1%;
  right: 0;
}

.line4 {
  height: 1%;
  width: 0;
  bottom: 0;
  right: 1%;
}

.box-lung {
  backdrop-filter: blur(15px);
  height: 36%;
  width: 36%;
  border-radius: 50%;
  position:absolute;
  background: rgba(255,255,255, 0.1);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* border: 4px solid #fff; */
}

.box-lung-decoration-1 {
  height: 0%;
  width: 0%;
  border-radius: 50%;
  position:absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(255,255,255, 0.3);
}

.box-lung-decoration-2 {
  height: 0%;
  width: 0%;
  border-radius: 50%;
  position:absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(255,255,255, 0.3);
}

.breath-out {
  height: 100px;
  width: 100px;
  background: #008000;
  margin: auto auto;
  border-radius: 50px;
  text-align: center;
  line-height: 200px;
}
.breath-out p {
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
}

.text {
  height: -2%;
  width: 100%;
  border-radius: 50%;
  position:absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  color: #fff;
}

.state-breathingbox .Breathbox-wrapper {
  /* animation: breathbox-intro 1s ease-in-out;
  -webkit-animation: breathbox-intro 1s ease-in-out; */
  animation: glow 2s infinite alternate;
  -webkit-animation: glow 2s infinite alternate;
}

@keyframes breathbox-intro {
  from {
    transform: rotate(90);
    opacity: 0;
    -webkit-filter: blur(40px);
    -moz-filter: blur(40px);
    -o-filter: blur(40px);
    -ms-filter: blur(40px);
    filter: blur(40px);
  }
  to {
    opacity: 1;
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }
}

.Breathbox-wrapper {
  background: rgba(255, 255, 255, .1);
  position: relative;
  font-size: 100%;
}

@keyframes glow {
  from {
    box-shadow: rgba(255, 255, 255, 0.4) 0px 50px 100px -20px, rgba(255, 255, 255, 0.5) 0px 30px 60px -30px;
  }
  to {
    box-shadow: rgba(255, 255, 255, 0.4) 0px 25px 50px -0px, rgba(255, 255, 255, 0.5) 0px 15px 30px -10px;
  }
}

#breathingbox-skip-intro {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

#breathingbox-close {
  position: absolute;
  top: 2%;
  right: 2%;
}

.box-lung, 
.menu-sequence, 
.success-sequence, 
.breathing-box-text,
.state-success #progress,
.state-menu #progress,
.state-success #breathingbox-close,
.state-menu #breathingbox-close,
.state-success #breathingbox-skip-intro,
.state-menu #breathingbox-skip-intro
{
  display: none;
}

.success-sequence h3 {
  font-weight: 400;
  font-size: .8em;
}

.menu-sequence h3 {
  font-size: .7em;
  font-weight: 400;
  margin: 0 auto;
}

.success-sequence h2,
.menu-sequence h2
{
  font-size: 1.5em;
  font-family: 'Alata', sans-serif;
  text-transform: uppercase;
  letter-spacing: .3em;
  font-weight: 100;
  margin: 0 0
}

.state-success .Breathbox-wrapper,
.state-menu .Breathbox-wrapper
{
  background:none;
  box-shadow: none;
  animation: none;
  /* box-shadow: rgba(255, 255, 255, 0.25) 0px 50px 100px -20px, rgba(255, 255, 255, 0.3) 0px 30px 60px -30px; */
}

.state-breathingbox .Breathbox-wrapper .breathing-box-text,
.state-breathingbox .Breathbox-wrapper .box-lung,
.state-success .Breathbox-wrapper .success-sequence,
.state-menu .Breathbox-wrapper .menu-sequence
{
  display: block;
}