#App-credits {
	position: absolute;
	bottom: 20px;
	right: 20px;
	font-size: 9px;
	text-align: right;
}

#App-credits a {
	color: #ffffff;
}

#App-credits a:hover {
	text-decoration: none;
}

.btn-ios {
	cursor: pointer;
  background: none;
  border: none;
  width: 100%;
}

.btn-ios img {
  width: 80%;
}

.btn-ios:active {
	transform: scale(0.98);
}

.btn-ios:active img {
	box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}